import 'bootstrap';
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills';
import swal from 'sweetalert2';
import imagesLoaded from 'imagesloaded';
import WOW from 'wow.js';

import 'animate.css'
import './styles/index.scss';

const API_URL = window.location.hostname === 'localhost' ? 'http://local.newclar.api/' : 'https://newclarapi.tribaldevelop.com';

const navBar = $('#mainNavbar');
const navIcon = $('i.material-icons');
const navBarBrand = $('.navbar-brand');
const brandName = $('.brand-name');
const brandH1 = $('h1');
const navLink = $('.nav-link');
const form = $('form#contactForm');
const app = $('#app');
const loader = $('#loader');

const customSwal = swal.mixin({
    allowEscapeKey: false,
    allowOutsideClick: false,
    backdrop: 'rgba(0, 0, 0, .75)',
    buttonsStyling: false,
    confirmButtonClass: 'btn btn-dark mx-auto',
    confirmButtonText: 'Volver',
});

var imgLoad = imagesLoaded('#app', {
    background: '#HeaderNewclar'
});

imgLoad.on('done', function (instance) {
    new WOW().init();

    $(document).ready(function () {
        loader.fadeOut(800, "linear", function () {

            var ojodesktop = $('#HeaderNewclar img')[1].classList.add('animated', 'fadeOut', 'delay-2s', 'fast');
            var ojomobile = $('#HeaderNewclar img')[3].classList.add('animated', 'fadeOut', 'delay-2s', 'fast');

            $("body").scrollspy({ target: ".navbar" });

            $(window).on('activate.bs.scrollspy', function (e) {
                let newHash = $(".nav-item > .nav-link.active").attr('href');
                history.replaceState(null, null, newHash);
                history.pushState(null, null, newHash);
            });

            var scroll = new SmoothScroll('a[href*="#"]', {
                easing: 'easeInOutCubic',
                updateURL: true,
                popstate: true,
            });

            navBar.on('show.bs.collapse', function () {
                navIcon.text("close");
                navBarBrand.addClass("menu-open");
                brandName.hide();
                brandH1.hide();
                // $('.navbar').addClass("fixed-top");
            });

            navBar.on('hidden.bs.collapse', function () {
                navIcon.text("menu");
                navBarBrand.removeClass("menu-open");
                brandName.show();
                brandH1.show();
                // $('.navbar').removeClass("fixed-top");
            });

            navBarBrand.click(hideMenu);
            navLink.click(hideMenu);

            // -----------------------------
            // Envio de form
            form.submit(function (event) {

                event.preventDefault();

                var formData = {
                    'fullname': $('input[name=fullname]').val(),
                    'email': $('input[name=email]').val(),
                    'phone': $('input[name=phone]').val(),
                    'comments': $('textarea[name=comments]').val()
                };

                $.ajax({
                    type: 'POST',
                    url: API_URL,
                    data: formData,
                    dataType: 'json',
                    encode: true,
                    beforeSend: function () {
                        customSwal({
                            type: 'info',
                            text: 'Estamos procesando tu consulta...',
                            showConfirmButton: false,
                        })
                    }
                })
                    .done(function (data) {
                        if (data.success) {
                            customSwal({
                                type: 'success',
                                text: 'Tu consulta se envió correctamente.',
                                onAfterClose: function () {
                                    form[0].reset();
                                    $(window).scrollTop(0);
                                }
                            })
                        } else {
                            customSwal({
                                type: 'error',
                                text: 'Ocurrió un error, por favor intentalo nuevamente más tarde.',
                            })
                        }
                    })
            });

        })
    })
});

function hideMenu() {
    navBar.collapse('hide');
}